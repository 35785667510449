// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  url:"https://devapi.netprophet.auto/sq/api/",
  //  url1: 'https://devchatapi.netprophet.auto/api/messenger/',
  //  admapi:"https://devadmapi.netprophet.auto/api/",  
  // ImageUrl:'https://devapi.netprophet.auto/api/v1/resources/',
  // AttachUrl: 'https://devapi.netprophet.auto/api/v1/resources/actionplanattachments/',
  // url3: 'https://devapi.netprophet.auto/api/',
  // ImgUrl: 'https://devadmapi.netprophet.auto/api/storelogo/',
  // ImageUrl2 : 'https://devchatapi.netprophet.auto/api/v1/resources/attachments/thumbs/',
  // ImageUrl1: 'http://devapi.netprophet.auto/api/v1/resources/sc_profileimage/',



  // *****************demo https *************************
  url:"https://demoapi.netprophet.auto/sq/api/",
  admapi:"https://demoadmapi.netprophet.auto/api/",
  ImageUrl:'https://demoapi.netprophet.auto/api/v1/resources/',
  AttachUrl: 'https://demoapi.netprophet.auto/api/v1/resources/actionplanattachments/',
  url3: 'http://demoapi.netprophet.auto/api/',
  ImgUrl: 'http://demoadmapi.netprophet.auto/api/storelogo/',
  url1: 'http://demochatapi.netprophet.auto/api/messenger/',
  ImageUrl2 : 'https://demochatapi.netprophet.auto/api/v1/resources/attachments/thumbs/',
  ImageUrl1: 'https://demoapi.netprophet.auto/api/v1/resources/sc_profileimage/',
  ImageUrl3 : 'https://demochatapi.netprophet.auto/api/v1/resources/groupicons/thumbs/',


  // //  // *****************prod *************************  
  // url: "https://appapi.netprophet.auto/sq/api/",
  // admapi: "https://appadminapi.netprophet.auto/api/",
  // ImageUrl: 'https://appadminapi.netprophet.auto/api/v1/resources/',
  // AttachUrl: 'https://appapi.netprophet.auto/api/v1/resources/actionplanattachments/',
  // url3: 'https://appapi.netprophet.auto/api/',
  // ImgUrl: 'https://appadminapi.netprophet.auto/api/storelogo/',
  // url1: 'https://npchatapi.netprophet.auto/api/messenger/',
  // ImageUrl2: 'https://npchatapi.netprophet.auto/api/v1/resources/attachments/thumbs/',
  // ImageUrl1: 'https://appapi.netprophet.auto/api/v1/resources/sc_profileimage/',
  // ImageUrl3: 'https://npchatapi.netprophet.auto/api/v1/resources/groupicons/thumbs/',
  firebase: {
    apiKey: "AIzaSyC7M-_PjEMRvD4bBDy4IREBQTeFhxAa3uQ",
    authDomain: "netprophet-auto.firebaseapp.com",
    projectId: "netprophet-auto",
    storageBucket: "netprophet-auto.appspot.com",
    messagingSenderId: "367734502919",
    appId: "1:367734502919:web:d4946bc04fa75a8d609ca2"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
