<section class="blk_strip">
    <div class="col-12">
        <div class="row">
            <div class="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-3">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0" *ngIf="this.comm.selectedlink != 1 || this.comm.grididividual==1">
                        <!-- <select class="brand-dropdown" (change)="selectstore($event.target.value)">
							<option [value]="item.Store_Id"
								[selected]="item.Store_Id == this.comm.localStorageItem('store_id')"
								*ngFor="let item of comm.storeinfo">

								{{item.Store_Name | limitChars:22}}</option>

						</select> -->
                        <dropdownlist [options]="comm.storeinfo" [optionText]="'Store_Name'" [optionValue]="'Store_Id'" (onValueChanged)="selectstore($event)" [selectedOption]="comm.selectedStore"></dropdownlist>

                        <!-- <div class="textfirst">Select Brand</div> -->

                    </div>
                    <div class="col-8 col-sm-5 col-md-5 col-lg-4 col-xl-4 grid d-flex align-items-center" *ngIf="this.comm.selectedlink == 1 && this.comm.grididividual==0">
                        <dropdownlist [options]="comm.storeinfo" [optionText]="'Store_Name'" [optionValue]="'Store_Id'" (onValueChanged)="selectstore($event)" [selectedOption]="comm.selectedStore"></dropdownlist>
                    </div>
                    <!-- <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 p-0 divider">|</div> -->
                </div>
            </div>
            <div style="padding:0;" class="col-8 col-sm-5 col-md-5 col-lg-5 col-xl-4 grid d-flex align-items-center">
                <!-- <select class="ddl" (change)='onFavChng($event)'>
					<option style="background-color: white;color:black">FAVORITES</option>
				    <option style="background-color: white;color:black" *ngFor="let f of favorites">{{f.bookmark}}</option>
			   </select> -->
                <button (click)="downarrow()" style="margin-top:.5rem;cursor: pointer;" type="button" data-toggle="dropdown" class="btn_drop"> FAVORITES 
			  <img _ngcontent-gph-c73="" src="assets/images/drop_arw.png" style="padding-left: 10px;">
			  </button>
                <div class="up-arrow" *ngIf="downarrow1">
                    <ul>
                        <li *ngFor="let f of favorites" (click)="onFavChng(f)" style="width: 100% !important;">
                            <a style="border:none;cursor: pointer;">{{f.bookmark}}</a>
                        </li>
                    </ul>
                </div>
                <h1 *ngIf="comm.storeId!='1000' &&  !(comm.moduleids.split(',').indexOf('40') > -1)" style="margin-right: 0rem;margin-left: 0;">Valuation 
                    <span style="font-size:1rem!important"> ${{getformat(valuation)}}<sup>*</sup>&nbsp;&nbsp;&nbsp;
                        <img src="../../assets/images/valuation.png" width="50px" style="width: 34px;position: absolute;bottom: 15px;">
                    </span>
                </h1>
                <h1 *ngIf="comm.storeId!='1000' && (comm.moduleids.split(',').indexOf('40') > -1)" (click)="gotovaluation()" style="cursor:pointer;margin-right: 0rem;margin-left: 0;">Valuation 
                    <span style="font-size:1rem!important"> ${{getformat(valuation)}}<sup>*</sup>&nbsp;&nbsp;&nbsp;
                        <img src="../../assets/images/valuation.png" width="50px" style="width: 34px;position: absolute;bottom: 15px;">
                    </span>
                </h1>
            </div>
            <!-- <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-0 pr-0"> -->

            <!-- <div *ngIf="this.comm.selectedlink == 1 && this.comm.grididividual==0" class="brand-dropdown" style="width: 11rem !important;
					padding: 10px 10px;" (click)="openMonthYearPopupGrp()">
								{{this.monthsToShowGrp}}
								{{this.yearToShowGrp}}
					</div> -->
            <!-- </div> -->
            <div style="padding:0;" class="col-7 col-sm-5 col-md-5 col-lg-5 col-xl-5  align-self-center">
                <div class="row">
                    <div class="col-8 text-right pr-xl-0" *ngIf="this.comm.selectedlink != 1 || this.comm.grididividual==1" (click)="openMonthYearPopup()">
                        <!-- <select (change)="selectmonthyear($event.target.value)" class="brand-dropdown"
								style="width: 11rem !important;">
								<option [value]="item.month_year[0]"
									[selected]="item.month_year[0] == this.comm.localStorageItem('monthYear')"
									*ngFor="let item of comm.MonthsDdl">{{item.month[0]}}
									{{item.year[0]}}</option>
							</select> -->
                        <div class="storeddl cur d-flex align-items-center justify-content-end" style="text-transform: uppercase;">
                            <!-- <img src="assets/images/Dashboard/data_icon.png" class="dmsimg"> -->
                            <p>
                                <!-- <span style="color:#0b95f4">DMS Data:</span> <span style="font-family:'Gotham Bold';color:#0b95f4"> {{this.parseConvertCur('month')}}
										{{this.parseConvertCur('year')}}</span>
                                <br> -->
                                <!-- {{this.yearToShowCur}} -->
                                <!-- </div>
							<div class=" storeddl cur d-flex align-items-center justify-content-start pr-4" >
								<img src="assets/images/Dashboard/switch_mark.png" class="bmk"> -->

                                <span style="color:#6ea04b">Benchmark Data:</span> <span style="font-family:'Gotham Bold';color:#6ea04b">
							{{this.parseConvert('month')}}
								{{this.parseConvert('year')}}
							</span>
                            </p>
                            <img src="assets/images/Dashboard/Calendar_icon.png" class="bmk">

                        </div>
                        <!-- <div class="storeddl cur d-flex align-items-center justify-content-center">
							</div> -->

                    </div>

                    <div class="col-4 align-self-center">
                        <div class="row">
                            <div class="col-4 text-right pr-xl-0 pt-1" *ngIf="!checkbookmark()" style="cursor: pointer;"><img src="assets/images/Favorite1.png" (click)="editbookmark(pop,'A')"></div>
                            <div class="col-4 text-right pr-xl-0 pt-1" *ngIf="checkbookmark()" style="cursor: pointer;"><img src="assets/images/Favorite-fill.png" (click)="editbookmark(pop,'U')"></div>
                            <div class="col-4 text-center"><img src="assets/images/Print.png" style="cursor:pointer;" (click)="printScreen()">
                            </div>
                            <div class="col-4 text-left pl-0 pt-1"><img src="assets/images/Email.png" style="cursor:pointer;" (click)="openModalForemailsent(templateemail)"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row" [ngClass]=" {hide: !monthYearPopup}">
            <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-4"></div>
            <div class="col-8 col-sm-3 col-md-3 col-lg-4 col-xl-5 grid d-flex align-items-center"></div>
            <div class="col-3 col-sm-5 col-md-5 col-lg-4 col-xl-2 p-0">
                <div class="calbox arrow-top" style="position: absolute;z-index: 9999999;padding:0px;">
                    <div class="container-fluid">
                        <div class="mth-pkr">

                            <div class="col-12" class="cal-top" style="text-align: center;font-size: 0.9rem;width:100%;padding:0px">
                                <!-- <div class="col-6"> -->
                                <!-- <div style="padding:10px">
                                        <label style="margin-left: 5px;font-family: 'Gotham Bold';color:#0b95f4">DMS Data
										</label></div>
                                    <div style="float:left;padding: 6px 6px 0px 0px;font-size: 1rem;">
                                        <label><span> {{curyear}}</span></label>
                                    </div> -->
                                <!-- <div class="cal-top1" style="border-bottom: none;float: right;width: 38%;padding:5px 0px">
                                        <div class="arr-btns mr-auto">
                                            <button class="btn" (click)="prevdms()" [ngClass]="(curyear > curminYear) ? 'opacity_on' : 'opacity_off'">
												<i class="fas fa-chevron-left"></i>
											</button>
                                            <button class="btn" (click)="nextdms()" [ngClass]="curyear < curmaxYear ? 'opacity_on' : 'opacity_off'">
												<i style="margin:auto 0 0 .05rem" class="fas fa-chevron-right"></i>
											</button>
                                        </div>
                                    </div> -->
                                <!-- </div> -->
                                <div class="col-12 p-0">
                                    <div style="padding:10px">
                                        <label style="margin-left: 5px;font-family: 'Gotham Bold';color:#6ea04b">Benchmark Data</label></div>

                                    <div class="cal-top" style="border-bottom: none;float: right;width: 30%;padding:5px 0px">
                                        <div class="arr-btns mr-auto">
                                            <button class="btn" (click)="prev()" [ngClass]="(yearToShow > minYear) ? 'opacity_on' : 'opacity_off'">
												<i class="fas fa-chevron-left"></i>
											</button>
                                            <button class="btn" (click)="next()" [ngClass]="yearToShow < maxYear ? 'opacity_on' : 'opacity_off'">
												<i style="margin:auto 0 0 .05rem" class="fas fa-chevron-right"></i>
											</button>
                                        </div>
                                    </div>
                                    <div style="float:right;padding: 6px 6px 0px 0px;font-size: 1rem;">
                                        <label><span> {{yearToShow}}</span></label>
                                    </div>
                                </div>
                            </div>
                            <div class="row months " *ngIf="monthDDL.length > 0">
                                <!-- <div class="col-6 dmsmonth" style="text-align: center;">
                                    <div class="row pr-2" style="padding: .2rem;">
                                        <div class="col-2" *ngFor="let item of ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'] ; let j=index">
                                            <label [ngClass]="getCheckedcurrent(item)?'boldFont':'boldNormal'" style="margin-right: 5px;margin-top: 5px;">{{item}}</label>
                                            <input type="radio" name="mth" (click)="handleChangecurrent(item,i)" *ngIf="checkToShowcurrent(j)" [checked]="getCheckedcurrent(item)" [value]="item">
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-12" style="text-align: center;">
                                    <div class="row pl-2" style="padding: .2rem;">
                                        <div class="col-2" *ngFor="let item of ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'] ; let i=index" style="min-height:44px">
                                            <label [ngClass]="getChecked(item)?'boldFont':'boldNormal'" style="margin-right: 5px;margin-top: 5px;">{{item}}</label>
                                            <input type="radio" name="th" (click)="handleChange(item,i)" *ngIf="checkToShow(item)" [checked]="getChecked(item)" [value]="item">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7 pt-3 pb-3 pl-4" style="text-align: end;">
                                    <div class="slt cur">
                                        <button class="sltbtn" (click)="selectChange()">Select</button>
                                    </div>
                                </div>
                                <div class="cnl cur col-5" style="align-self: center;" (click)="closeMonnthYearPopup()">
                                    <a>Cancel</a>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 1000%;width: 1000%;position: fixed;left: -100%;top: -100%;bottom: -100%;z-index:999999;" (click)="openMonthYearPopup()" [ngClass]="{hide: !monthYearPopup}"></div>
            </div>
        </div>

    </div>
    <div class="flex-item flex1" [ngClass]="{hide: !monthYearPopup}" (click)="openMonthYearPopup()">
    </div>
    <div class="row" [ngClass]=" {hide: !monthYearPopupGrp}">
        <div class="col-lg-3">

        </div>
        <div class="col-lg-4">
            <div class="calbox arrow-top" style="position: absolute;z-index: 9999;">
                <div class="container-fluid">
                    <div class="mth-pkr" style="width: 13rem;">
                        <div class="cal-top">
                            <div style="width: 75%;">
                                <label>
									<span> {{monthYearGrp}}</span></label>
                            </div>
                            <div class="arr-btns mr-auto" style="margin-right: auto;width: 35%;">
                                <button class="btn" (click)="prevGrp()" [ngClass]="(monthYearGrp > minYearGrp) ? 'opacity_on' : 'opacity_off'">
									<i class="fas fa-chevron-left"></i>
								</button>
                                <button class="btn" (click)="nextGrp()" [ngClass]="monthYearGrp < maxYearGrp ? 'opacity_on' : 'opacity_off'">
									<i class="fas fa-chevron-right"></i>
								</button>
                            </div>
                        </div>



                        <div class="row months" style="max-width: 223px;padding: 0 0 0 7%;" *ngIf="monthDDL.length > 0">
                            <div class="col-2" style="max-width: 44px;padding: .2rem;" *ngFor="let item of ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'] ; let i=index">
                                <label [ngClass]="boldFont">{{item}}</label><br />
                                <input type="radio" name="mth" (click)="handleChangeGrp(item,i)" [checked]="getcheckedgrp(item)">
                            </div>
                        </div>
                        <div class="row">
                            <div class="cal-btm">
                                <div class="slt cur">
                                    <button class="sltbtn" (click)="changeDate()">Select</button>
                                </div>
                                <div class="cnl cur" (click)="closeMonnthYearPopupGrp()">
                                    <a>Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-item flex1" [ngClass]="{hide: !monthYearPopupGrp}" (click)="openMonthYearPopupGrp()">
    </div>
</section>



<ng-template class="popup-dialog" #pop let-modal style="top:20rem">
    <app-commo-alert [alertType]="typeAlert" (onCloseAlert)="onCloseAlert($event,template)" [alertContent]="alertContent"></app-commo-alert>
</ng-template>

<ng-template class="recommand" #templateemail let-modal>
    <app-loader-animation *ngIf="isLoading"></app-loader-animation>

    <div class="modal-header" style="padding: 0 !important;">
        <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="row cre_header">
                <div class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                    <h6>Send Email </h6>
                </div>
                <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 cur" style="text-align: right;">
                    <img src="assets/images/cls_icon.png" (click)="closeMailPopup()" alt="close">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body" style="padding: 0rem 0rem 1.5rem;background-color: #e8ebee;">
        <div class="container">
            <form [formGroup]="sentEmail" (ngSubmit)="submitEmail()">
                <div class="row justify-content-center">

                    <div class="col-12 col-lg-7 settngs_panel">
                        <div class="row">
                            <div class="col-12 col-lg-12">
                                <div class="formgroup">
                                    <label>To:</label>
                                    <input type="text" maxlength="200" class="form-control" formControlName="sentto">
                                </div>
                                <span class="text-danger" *ngIf="(sentEmail.get('sentto').touched || submitted) && sentEmail.get('sentto').errors?.required">
										Email Id is required
									</span>
                            </div>
                            <div class="col-12 col-lg-12">
                                <div class="formgroup">
                                    <label>Cc:</label>
                                    <input type="text" maxlength="200" class="form-control" formControlName="senttocc">
                                </div>
                            </div>
                            <div class="col-12 col-lg-12">
                                <div class="formgroup">
                                    <label>Bcc:</label>
                                    <input type="text" maxlength="200" class="form-control" formControlName="senttobcc">
                                </div>
                            </div>
                            <div class="col-12 col-lg-12">
                                <div class="formgroup">
                                    <label>Subject:</label>
                                    <input type="text" maxlength="100" class="form-control" formControlName="subject">
                                </div>
                            </div>
                            <div class="col-12 col-lg-12">

                                <div class="formgroup">
                                    <label>Text:</label>
                                    <!-- <input type="text" maxlength="40" class="form-control"	formControlName="subject">	 -->
                                    <textarea name="Text1" class="form-control" formControlName="text" cols="40" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="col-12 col-lg-12 pt-5 text-center">
                                <input type="submit" class="btndark" value="Send" style="border:none">
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>