import { Component, OnInit, Renderer2 } from '@angular/core';
import { Common } from './common';
import { AngularFireMessaging } from '@angular/fire/messaging';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(public comm: Common, private render: Renderer2,public angularFireMessaging:AngularFireMessaging) {


  //  this.requestPermission();
  //  this.receiveMessage();

  }
  title = 'NetProphet';

  ngOnInit() {
    console.log(window.location.href.includes('/2'));
    if (window.location.href.includes('/2')) {
      //this.comm. = true;
      this.comm.selectedlink = 2;
      this.comm.selectedItem = 0;
    } else {
      //this.comm.actionPlanActiveFlag = false;
      this.comm.selectedlink = 0;
    }

    $(function () {
      // Set
      var main = $('.brand-dropdown .textfirst')
      var li = $('.brand-dropdown > ul > li.input-option')
      var inputoption = $(".brand-dropdown .option")
      var default_text = 'Select Brand';

      // Animation
      main.click(function () {
        main.html(default_text);
        li.toggle('fast');
      });

      // Insert Data
      li.click(function () {
        // hide
        li.toggle('fast');
        var livalue = $(this).data('value');
        var lihtml = $(this).html();
        main.html(lihtml);
        inputoption.val(livalue);
      });

    });

  }

  ngDoCheck() {
    // if (window.location.href.includes('/messenger')) {
    //  this.render.setStyle(document.body, 'overflow-y', 'hidden')
    // } else {
       this.render.removeStyle(document.body, 'overflow-y')
    // }
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
    (token) => {
    console.log('fcm:'+token);
    },
    (err) => {
    console.error('Unable to get permission to notify.', err);
    }
    );
    }

    receiveMessage() {
      this.angularFireMessaging.messages.subscribe(
      (payload) => {
      console.log("new message received. ", payload);
     // this.currentMessage.next(payload);
      })
      }
    
  }

