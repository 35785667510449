import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChildren, ViewChild, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner/lib/ngx-spinner.service';
import { Common } from '../common';
import { NPApi } from '../Providers/NP-Api';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { LinechatComponent } from '../linechat/linechat.component';
import * as moment from 'moment';
import { AlertifyService } from 'src/app/Providers/alertify/alertify.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

declare var require: any;
@Component({
  selector: 'app-common-header-strip',
  templateUrl: './common-header-strip.component.html',
  styleUrls: ['./common-header-strip.component.scss']
})
export class CommonHeaderStripComponent implements OnInit {
  storeinfo: any = [];
  monthYearCal: any;
  first = 1;
  last = 12;
  yearToShow: any;
  monthsToShow: any;
  yearToShowGrp: any;
  monthsToShowGrp: any;
  // dashInfo: any = [];
  // cash: any;
  // gross: any;
  // expence: any;
  // comp: any;
  // profit: any;
  // stafig: any;
  // revenue: any;
  // staffing: any;
  // Dinfo: any;
  // monthvalues: any;
  // total; any;
  // selectedItem: any = 0;
  // MonthsDdl: any;
  monthyear: any = 0;
  Viewdata: any;
  innerkeys: any;
  viewname: any = "";
  actual: any;
  viewflag: any = 0;
  showdesc: boolean;
  viewlen: any = 0;
  userInfo: any;
  moduleInfo: any;
  dash_sec_grads: any;
  selectedStore: {};
  @Output() changeStoreInfo = new EventEmitter();
  @Output() changeMonthYear = new EventEmitter();
  @Output() changeGrid = new EventEmitter();
  @Output() bindPerformancedata = new EventEmitter();
  @Output() bindSummeryPerformance = new EventEmitter();
  @Output() changeAP = new EventEmitter();
  @Output() binddetailsdata = new EventEmitter();
  @Output() bindbookmarkdata = new EventEmitter();
  @Output() bindvaluation = new EventEmitter();
  @ViewChild('popup', { static: false }) popup: ElementRef;
  @ViewChild('menu', { static: false }) menu: ElementRef;
  monthYear: any;
  monthYearGrp: any;
  selectedmonthYearGrp: any;
  selectedYearGrp: any;
  monthYearPopup = false;
  monthYearPopupGrp = false;
  // @Output() trendsdata = new EventEmitter();
  currentMonth: any;
  maxDate: Date;
  maxYearGrp = 0;
  minYearGrp = 0;
  // mnthval:any;
  mnthvalCur: any;
  yearToShowSelCur: any;
  curyear: any = '';

  sentEmail: FormGroup;
  submitted = false;
  isLoading = false;
  constructor(public comm: Common, private api: NPApi, private router: Router, private ngbmodal: NgbModal, private fb: FormBuilder,
    private ngbmodalActive: NgbActiveModal, private alertify: AlertifyService,
    private ngbActiveModal: NgbActiveModal, private renderer: Renderer2, private cdr:ChangeDetectorRef) {
    // this.viewflag = 0;
    this.comm.head = true;
    // console.log(this.comm.localStorageItem('store_id'))
    // console.log(this.comm.localStorageItem('monthYear'))
    //this.spinner.show();
    //  setTimeout(() => {
    /** spinner ends after 5 seconds */
    //this.spinner.hide();
    //}, 3000);
    this.currentMonth = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.monthsToShowGrp = this.getmonth(this.maxDate.getMonth());
    this.yearToShowGrp = this.maxDate.getFullYear();
    this.maxYearGrp = this.yearToShowGrp;
    this.minYearGrp = this.yearToShowGrp - 5;
    this.userInfo = JSON.parse(localStorage.getItem("npUserInfo"));

    this.moduleInfo = localStorage.getItem("npModuleIds");
    this.comm.moduleids = this.moduleInfo;
    console.log(this.maxDate);
    this.getValuationData();
    this.Getfavdata();
    this.api.getStoresInfo(this.userInfo.id).subscribe((data: any) => {
      console.log(data);
      //let data1=data;
      console.log(this.comm.storeinfo);
      // if(this.comm.storeinfo.length == 0){
      //   if(this.comm.selectedlink!=1)
      this.comm.storeinfo = (data.status == 200) ? JSON.parse(JSON.stringify(data.response)) : [];
      
      // this.comm.selectedItem = 1;
      if (this.comm.localStorageItem('store_id') == '') {
        let MonthsDdl = this.parseXml(this.comm.storeinfo[0].monthsdata);
        this.comm.MonthsDdl = MonthsDdl.Months.month;
        console.log(MonthsDdl.Months.month, 'mon');
        localStorage.setItem('store_id', this.comm.storeinfo[0].Store_Id);
        localStorage.setItem("selecMonth", JSON.stringify(this.comm.MonthsDdl[0]));
        this.api.setActionPlanActualValueObs(this.comm.MonthsDdl[0]);
        let Weightavg = (this.comm.storeinfo[0].Weightavg != '') ? this.parseXml(this.comm.storeinfo[0].Weightavg) : [];
        let wavg = (this.comm.storeinfo[0].Weightavg != '') ? JSON.stringify(Weightavg.weightedavg.avgdata) : "";
        localStorage.setItem('WeightAvg', wavg);
        console.log('WeightAvg' + wavg);

        this.monthYear = JSON.parse(this.comm.localStorageItem("selecMonth"));
        this.monthYearCal = this.monthYear;

        localStorage.setItem('monthYear', this.comm.MonthsDdl[0].month_year[0]);
        this.monthDDL = this.comm.MonthsDdl.length > 12 ? this.pagenatation() : this.comm.MonthsDdl;
        this.i = 0;
        localStorage.setItem('axelonecust', (this.comm.storeinfo[0].Isaxelonecust == 'Y') ? 'true':'false');
        //this.checkedRadiobuttons();
      }

      for (let i = 0; i < this.comm.storeinfo.length; i++) {
        if (this.comm.storeinfo[i].Store_Id == this.comm.localStorageItem('store_id')) {
          let MonthsDdl = this.parseXml(this.comm.storeinfo[i].monthsdata);
          this.comm.store_title = this.comm.storeinfo[i].Store_Name;
          this.comm.MonthsDdl = MonthsDdl.Months.month;
          // if (this.comm.selectedlink != 1)
          this.comm.selectedStore = this.comm.storeinfo[i];
          //   this.selectstoreforinit(this.userInfo.sc_defaultstore);
          console.log(MonthsDdl.Months.month, 'mon');
          //localStorage.setItem("selecMonth", this.comm.MonthsDdl[0])
          if (JSON.parse(localStorage.getItem("selecMonth")) == null)
            localStorage.setItem("selecMonth", JSON.stringify(this.comm.MonthsDdl[0]));
          this.api.setActionPlanActualValueObs(this.comm.MonthsDdl[0]);

          this.monthYear = JSON.parse(this.comm.localStorageItem("selecMonth"));
          this.monthYearCal = this.monthYear;
          console.log(this.comm.MonthsDdl);
          this.monthDDL = this.comm.MonthsDdl.length > 12 ? this.pagenatation() : this.comm.MonthsDdl;
          this.i = 0;
          //this.checkedRadiobuttons();
          let Weightavg = (this.comm.storeinfo[i].Weightavg != '') ? this.parseXml(this.comm.storeinfo[i].Weightavg) : [];
          let wavg = (this.comm.storeinfo[i].Weightavg != '') ? JSON.stringify(Weightavg.weightedavg.avgdata) : "";
          localStorage.setItem('WeightAvg', wavg);
          console.log('WeightAvg' + wavg);
          localStorage.setItem("npModuleIds", this.comm.storeinfo[0].moduleIds);
          this.comm.moduleids = this.comm.storeinfo[0].moduleIds;
          console.log(this.comm.moduleids);
          localStorage.setItem('axelonecust', (this.comm.storeinfo[i].Isaxelonecust == 'Y') ? 'true':'false');

        }
        // for (let i = 0; i < this.comm.storeinfo.length; i++) {
        //   if (this.comm.storeinfo[i].Store_Id == this.comm.localStorageItem('store_id')) {
        //     let MonthsDdl = this.parseXml(this.comm.storeinfo[i].monthsdata);
        //     this.comm.store_title = this.comm.storeinfo[i].Store_Name;
        //     this.comm.MonthsDdl = MonthsDdl.Months.month;
        //     this.comm.selectedStore = this.comm.storeinfo[i];
        //     console.log(MonthsDdl.Months.month, 'mon');
        //     //localStorage.setItem("selecMonth", this.comm.MonthsDdl[0])
        //     localStorage.setItem("selecMonth", JSON.stringify(this.comm.MonthsDdl[0]));
        //     this.monthYear = JSON.parse(localStorage.getItem("selecMonth"));
        //     localStorage.setItem('monthYear', this.comm.MonthsDdl[0].month_year[0]);
        //     this.monthYearCal = this.monthYear;
        //     console.log(this.comm.MonthsDdl);
        //     this.monthDDL = this.comm.MonthsDdl.length > 12 ? this.pagenatation() : this.comm.MonthsDdl;
        //     this.i = 0;
        //     //this.checkedRadiobuttons(); 
        //   }
        // }
      }
      // }
      this.getvaluationvalue(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));
      console.log(this.comm.localStorageItem('store_id'));
      this.comm.storeId = this.comm.localStorageItem('store_id');
    });
    //}
    this.checkbookmark();
    this.Getfavdata();

    this.sentEmail = this.fb.group({
      sentto: ['', [Validators.required]],
      senttocc: [''],
      senttobcc: [''],
      subject: [''],
      text: ['']
    })

    this.renderer.listen('document', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (this.popup && this.menu) {
        const popupElement = this.popup.nativeElement;
        const menuElement = this.menu.nativeElement;
      if (e.target !== this.popup.nativeElement && e.target !== this.menu.nativeElement) {
        this.downarrow1 = false;
      }
    }
    });
  }

  parseXml(xmlStr) {
    var result;
    var parser = require('xml2js');
    parser.Parser().parseString(xmlStr, (e, r) => { result = r });
    return result;
  }

  ngOnInit(): void {
    // console.log('hfjhsfjgsjkgfhdfgvjhbdjfhmhdfgjdhfg');



  }


  // parseXml(xmlStr) {
  //   var result;
  //   var parser = require('xml2js');
  //   parser.Parser().parseString(xmlStr, (e, r) => { result = r });
  //   return result;
  // }



  valdata: any = [];
  selectstore(id: any) {
    //getted from event
    this.comm.storeId = id;
    console.log('comm storeid' + this.comm.storeId)
    console.log(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));
    this.comm.selectedStore = this.comm.storeinfo[0];
    for (let i = 0; i < this.comm.storeinfo.length; i++) {
      if (this.comm.storeinfo[i].Store_Id == id) {
        let MonthsDdl = this.parseXml(this.comm.storeinfo[i].monthsdata);
        this.comm.store_title = this.comm.storeinfo[i].Store_Name;
        this.comm.MonthsDdl = MonthsDdl.Months.month;
        console.log(this.comm.MonthsDdl, 'llo');
        this.comm.selectedStore = this.comm.storeinfo[i];
        localStorage.setItem('monthYear', this.comm.MonthsDdl[0].month_year[0]);
        console.log(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));

        let Weightavg = (this.comm.storeinfo[i].Weightavg != '') ? this.parseXml(this.comm.storeinfo[i].Weightavg) : [];
        let wavg = (this.comm.storeinfo[i].Weightavg != '') ? JSON.stringify(Weightavg.weightedavg.avgdata) : "";
        localStorage.setItem('WeightAvg', wavg);
        console.log('weightavg' + wavg);
        this.getValuationData();
        this.Getfavdata();
        this.checkbookmark();
        localStorage.setItem('axelonecust', (this.comm.storeinfo[i].Isaxelonecust == 'Y') ? 'true':'false');

      }
    }
    this.first = 1;
    this.last = 12;
    localStorage.setItem("selecMonth", JSON.stringify(this.comm.MonthsDdl[0]));
    this.api.setActionPlanActualValueObs(this.comm.MonthsDdl[0]);
    this.monthYear = JSON.parse(localStorage.getItem("selecMonth"));

    this.monthYearCal = this.monthYear;
    this.monthDDL = [];
    this.i = 0;
    this.monthDDL = this.comm.MonthsDdl.length > 12 ? this.pagenatation() : this.comm.MonthsDdl;
    //this.checkedRadiobuttons();
    localStorage.setItem('store_id', id);
    //  this.viewflag = 0;
    this.api.setStoreValue(id);
    console.log(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));
    this.getvaluationvalue(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));
    this.getdashsection_grades(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));
    // this.comm.selectedlink = 3;
    this.getnotificationsList();
    this.changeStoreInfo.emit({ storeId: id })

  }

  selectmonthyear(id: any) {
    //    this.viewflag = 0;
    this.monthyear = id;
    localStorage.setItem('monthYear', id);

    this.changeMonthYear.emit({ monthYear: id })
  }

  gridclick() {
    this.comm.selectedlink = 1;
    this.comm.grididividual = 1;
    this.changeGrid.emit(this.router.navigate(['4']))
  }

  bindPerformance() {
    this.comm.dbflag = 1;
    this.comm.selectedlink = 3;
    this.bindPerformancedata.emit(this.router.navigate(['6']))
  }
  bindSummery_Performance() {
    setTimeout(() => {
      // this.comm.dbflag = 2;
      this.comm.selectedlink = 10;
      this.bindSummeryPerformance.emit(this.router.navigate(['1']))
    }, 1000);
  }

  binddetails() {
    setTimeout(() => {
      this.comm.dbflag = 2;
      this.comm.selectedlink = 4;
      this.binddetailsdata.emit(this.router.navigate(['1']))
    }, 1000);
  }

  APclick() {
    this.comm.selectedlink = 2;
    //this.comm.selectedItem = 1;
    this.comm.pageid = 2;
    //  this.comm.fromHEAD = false;
    this.changeGrid.emit(this.router.navigate(['2']))
  }
  gotovaluation() {
    this.comm.val_flag = 1;
    this.comm.selectedlink = 3;
    this.router.navigate(['/6']);
  }

  //selectedLinkPreviousValue: number;
  // Trendsclick() {
  //   this.selectedLinkPreviousValue = this.comm.selectedlink;
  //   //console.log(this.selectedLinkPreviousValue, 'kk');

  //   this.comm.selectedlink = 5;
  //   // this.trendsdata.emit();
  //   //this.ngbmodalActive = this.ngbmodal.open(LinechatComponent, { size: 'lg', backdrop: 'static' });
  //   const modalRef = this.ngbmodal.open(LinechatComponent, { size: 'lg', backdrop: 'static' });
  //   modalRef.componentInstance.previousValue = this.selectedLinkPreviousValue;
  // }

  getvaluationvalue(id: any, monthYear: any) {
    this.api.ValuationValue(id, monthYear).subscribe(
      (data: any) => {
        this.valdata = data.response;
      });
  }


  getdashsection_grades(id: any, monthYear: any) {
    this.api.Dash_sectionGrds(id, monthYear).subscribe(
      (data: any) => {
        this.dash_sec_grads = data.response;
        console.log(this.dash_sec_grads);

        data.response.filter(item => {
          if (item.section_name == 'cash') {
            this.comm.gra_cash = item.section_grade;
          }
          if (item.section_name == 'expense') {
            this.comm.gra_expense = item.section_grade;
          }
          if (item.section_name == 'revenue') {
            this.comm.gra_revenue = item.section_grade;
          }
          if (item.section_name == 'profit') {
            this.comm.gra_profit = item.section_grade;
          }
          if (item.section_name == 'compensation') {
            this.comm.gra_comp = item.section_grade;
          }
        })
        console.log(this.comm.gra_cash, this.comm.gra_expense, this.comm.gra_revenue, this.comm.gra_profit, this.comm.gra_comp)
        //  gra_cash:any='D';
        //  gra_revenue:any='D';
        //  gra_expense:any='D';
        //  gra_comp:any='D';
        //  gra_profit:any='D';

      });
      this.cdr.detectChanges();
  }


  // viewDetils() {
  //   this.viewflag = 1;
  //   //this.spinner.show();
  //   setTimeout(() => {
  //     /** spinner ends after 5 seconds */
  //     //this.spinner.hide();
  //   }, 2000);
  //   let storeid = localStorage.getItem('store_id');

  //   //      this.api.ViewDetails(storeid,this.monthyear,this.viewname).subscribe((data:any)=>{
  //   //          this.Viewdata=[];
  //   //          let Viewdata=data.response.Details_view.Subsection.reverse();
  //   //          this.Viewdata=Viewdata;
  //   //          this.viewlen=this.Viewdata.length;
  //   //           this.showdesc=(this.Viewdata[this.viewlen-1].subsection.length==1 || this.Viewdata[this.viewlen-1].subsection.length==2)?true:false;
  //   //            console.log('sowdesc: '+this.showdesc)
  //   //           //  let keys=Object.keys(this.Viewdata).join(",").split(',');
  //   //       //  this.viewname=(this.viewname=="")?'cash':this.viewname;
  //   //       //    console.log('actual : '+this.viewname);
  //   //       //   this.actual=this.Viewdata[this.viewname];
  //   //       //     this.innerkeys=Object.keys(this.actual).join(",").split(',')
  //   //       //    console.log('cashactual: '+this.Viewdata[this.viewname].innerkeys[0].length);
  //   // 
  //   //      });
  // }

  // sideMenuChange(data) {
  //   this.getsection(data.objs)
  // }
  // getsection(secid) {
  //   this.viewflag = 0;
  //   let i = localStorage.getItem('vv')
  //   if (secid == i) {
  //     this.Dinfo = this.cash;
  //     this.selectedItem = 1;
  //   } if (secid == i) {
  //     this.Dinfo = this.gross;
  //     this.selectedItem = 2;
  //   }
  //   if (secid == i) {
  //     this.Dinfo = this.expence;
  //     this.selectedItem = 3;
  //   }
  //   if (secid == 4) {
  //     this.Dinfo = this.comp;
  //     this.selectedItem = 4;
  //   }
  //   if (secid == 5) {
  //     this.Dinfo = this.profit;
  //     this.selectedItem = 5;
  //   }
  //   if (secid == 6) {
  //     this.Dinfo = this.revenue;
  //     this.selectedItem = 6;
  //   }
  //   if (secid == 7) {
  //     this.Dinfo = this.staffing;
  //     this.selectedItem = 7;
  //   }

  //   this.dashInfo.Dash[0].totalval.filter(item => {

  //     if (item.section_name == 'cash' && secid == 1) {
  //       this.viewname = 'cash';
  //       this.total = item;
  //     }
  //     else if (item.section_name == 'expense' && secid == 3) {
  //       this.total = item;
  //       this.viewname = 'expense';
  //     }
  //     else if (item.section_name == 'compensation' && secid == 4) {
  //       this.total = item;
  //       this.viewname = 'compensation';
  //     }
  //     else if (item.section_name == 'profit' && secid == 5) {
  //       this.total = item;
  //       this.viewname = 'profit';
  //     }
  //     else if (item.section_name == 'gross' && secid == 6) {
  //       this.total = item;
  //       this.viewname = 'revenue';
  //     }
  //     else if (item.section_name == 'staffing' && secid == 7) {
  //       this.total = item;
  //       this.viewname = 'staffing';
  //     }
  //   })


  //   console.log(this.total);
  //   // this.monthvalues= this.Dinfo.section[0].Prev_Months[0].split(',');

  // }

  // GetDashBoard(id, flag) {

  //   if (flag != 0) {
  //     //this.spinner.show();
  //     setTimeout(() => {
  //       /** spinner ends after 5 seconds */
  //       //this.spinner.hide();
  //     }, 1000);
  //   }



  //   let exp = (flag == 0 || flag == 1) ? "" : this.monthyear.trim()
  //   this.api.GetDash(id, exp).subscribe((data: any) => {
  //     let dashInfo = (data.status == 200) ? data.response : [];
  //     //console.log(dashInfo);
  //     localStorage.setItem('store_id', id);
  //     this.dashInfo = dashInfo.Dashboard;
  //     if (exp == "")
  //       this.MonthsDdl = this.dashInfo.ddlmonths[0].Month

  //     console.log(this.dashInfo);

  //     //  console.log(this.MonthsDdl[0].month_year[0]);
  //     // this.Dinfo=element;
  //     this.monthyear = (this.monthyear == 0) ? this.MonthsDdl[0].month_year[0] : this.monthyear;
  //     localStorage.setItem('monthYear', this.monthyear);
  //     this.selectedItem = 1;
  //     this.viewname = 'cash';
  //     this.Dinfo = this.dashInfo.Dash[0].Sections[0];
  //     this.total = this.dashInfo.Dash[0].totalval[0];

  //     // this.monthvalues= this.Dinfo.section[0].Prev_Months[0].split(',');


  //     // console.log(this.monthvalues);
  //     this.dashInfo.Dash[0].Sections.forEach(element => {
  //       console.log(element);

  //       if (element.section[0].Section[0] == 'cash') {
  //         this.cash = element;
  //       }
  //       else if (element.section[0].Section[0] == 'expense') {
  //         this.expence = element;

  //       }
  //       else if (element.section[0].Section[0] == 'profit') {
  //         this.profit = element;
  //       }
  //       else if (element.section[0].Section[0] == 'revenue') {
  //         this.revenue = element;
  //       }
  //       else if (element.section[0].Section[0] == 'gross') {
  //         this.revenue = element;
  //       }
  //       else if (element.section[0].Section[0] == 'compensation') {
  //         this.comp = element;
  //       }

  //       else if (element.section[0].Section[0] == 'staffing') {
  //         this.staffing = element;
  //       }
  //       //staffing  
  //       //this.spinner.hide();
  //     }, error => {
  //       // this.spinner.hide();
  //     });
  //     // this.comp=this.comp.section.length>0?
  //     // console.log(this.comp.section.length)
  //     //console.log(this.cash.section.length);
  //     // console.log(this.cash);

  //   });

  // }
  // splitvalue(str, posin) {
  //   let pmval = str.split(',');
  //   let mval = (pmval.length >= 3) ? pmval[posin].split('|')[1] : "00";
  //   return mval;
  // }
  // bindmonthdirc(str, posin) {
  //   let pmval = str.split(',');
  //   let mval = (pmval.length >= 3) ? pmval[posin].split('|')[2] : 'D';
  //   //this.binddirc(mval);
  //   let url = "";
  //   return url = (mval == 'U') ? 'assets/images/sml_grn_arw.png' : 'assets/images/sml_red_arw.png';
  // }
  // splitmontval(str, posin, pos2) {
  //   let pmval = str.split(',');
  //   let val = (pmval.length >= 3) ? pmval[posin].split('|')[0] : "00";
  //   let mon = "";
  //   if (pos2 == 1)
  //     mon = val.substr(2, 2);
  //   else
  //     mon = val.substr(4, 2);

  //   // console.log(mon);
  //   return mon;
  // }


  // toFixedSpecial(num:any) {
  //    var x= this.convert(num);
  //   // console.log()
  //   let n=x.includes('.')?x:x+'.'+'00';
  //    let parts =n.toString().split(".");
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // parts[1]=parts[1].substr(0,2);
  // parts=parts[0]+"."+parts[1];
  // console.log(parts);
  // return parts;
  //    //,{exponential:{lower:1e-100,upper:1e100}}))
  // }

  // toFixedSpecial(number) {

  //   var n = number < 0 ? number.slice(1) : number;
  //   // if(n.length>=7){
  //   //   let num=this.test(n);
  //   //   return num;
  //   // }else

  //   var parts = n.toString().split(".");
  //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   if (parts.length == 2)
  //     parts[1] = parts[1].replace(/^\d+[.,]?\d{0,2}$/g);
  //   if (parts[1] > 0)
  //     return parts.join('.');
  //   else
  //     return parts[0];

  //   //  var n = num < 0 ? num.slice() : "",
  //   // return n.toLocaleString();
  //   // var [lead,decimal,pow] = n.toString()
  //   //     .replace(/^-/,"")
  //   //     .replace(/^([0-9]+)(e.*)/,"$1.$2")
  //   //     .split(/e|\./);
  //   // return +pow < 0 
  //   //     ? "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
  //   //     :  lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
  // }
  bind_grade(grd) {
    let url = 'assets/images/menuicons/';
    if (grd == 'A')
      url = url + 'leaf.png';
    else if (grd == 'B')
      url = url + 'Water.png'
    if (grd == 'C')
      url = url + 'Caution.png';
    else if (grd == 'D')
      url = url + 'Fire.png'

    return url;
  }

  binddirc(dirc) {
    let url = "";
    return url = (dirc == 'U') ? 'assets/images/sml_grn_arw.png' : 'assets/images/sml_red_arw.png';
  }


  //  test (labelValue) 
  // {
  //     // Nine Zeroes for Billions
  //     return Math.abs(Number(labelValue)) >= 1.0e+9

  //     ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
  //     // Six Zeroes for Millions 
  //     : Math.abs(Number(labelValue)) >= 1.0e+6

  //     ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
  //     // Three Zeroes for Thousands
  //     : Math.abs(Number(labelValue)) >= 1.0e+3

  //     ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

  //     : Math.abs(Number(labelValue));
  // }

  monthYearCalCopy: any;
  radioButtonStatus = false;
  getChecked(item) {
    // if (flag != 'D') {
    //     if (this.i != -1) {
    //       if (this.comm.MonthsDdl[this.i] != undefined) {
    //         if (item.year == this.comm.MonthsDdl[this.i].year[0]) {
    //           if (item.short_month == this.comm.MonthsDdl[this.i].short_month[0]) {
    //             this.monthYearCalCopy = item;
    //             this.radioButtonStatus = true;
    //             return true;
    //           } else {
    //             return false
    //           }
    // 
    //         }
    //       }
    // 
    //     } else {
    //       if (item.year == this.monthYear.year[0]) {
    //         if (item.short_month == this.monthYear.short_month[0]) {
    //           this.monthYearCalCopy = item;
    //           this.radioButtonStatus = true;
    //           return true;
    //         } else {
    //           return false
    //         }
    // 
    //       }
    //     }

    //for (let i = 0; i < this.monthDDL.length; i++) {
    if (this.yearToShow == this.monthYear.year[0]) {
      if (item == this.monthYear.short_month[0]) {
        //this.monthYearCal = this.monthDDL[i];
        return true;
      }
      ///}
    }


    //this.cdr.detectChanges();

  }

  handleChange(item, i) {
    //console.log(i, item);

    // this.monthyear = item;
    //     this.monthYearCal = item;
    //     this.i = i;
    // this.monthsToShow = item;
    for (let i = 0; i < this.monthDDL.length; i++) {
      if (this.yearToShow == this.monthDDL[i].year[0]) {
        if (item == this.monthDDL[i].short_month[0]) {
          this.monthYearCal = this.monthDDL[i];
        }
      }
    }
  }

  openMonthYearPopup() {
    //alert('hi')
    this.mnthvalCur = localStorage.getItem("CurrentMnth");
    this.yearToShowSelCur = localStorage.getItem("CurrentYear");
    let variable = JSON.parse(localStorage.getItem("selecMonth"));
    this.filterByYear(variable.year[0])
    this.monthYearCal = variable;
    this.monthYear = variable;
    this.monthDDL = [];
    //this.getChecked(this.monthDDL)
    this.pagenatation();
    this.yearToShow = variable.year[0];
    this.monthsToShow = variable.month[0]
    this.i = this.retunIndexValue();
    this.findMaxMinYear();
    ///console.log(this.yearToShow);
    this.j = 0;
    this.curyear = this.yearToShowSelCur;
    this.curmaxYear = this.maxDate.getFullYear();
    console.log(this.maxYear);
    console.log(this.minYear);
    this.monthYearPopup = !this.monthYearPopup;

  }
  selectChange() {
    //localStorage.setItem('monthYear', this.monthYear)
    localStorage.setItem("CurrentMnth", this.mnthvalCur);
    localStorage.setItem("CurrentYear", this.yearToShowSelCur);
    localStorage.setItem("selecMonth", JSON.stringify(this.monthYearCal));
    this.monthYear = JSON.parse(localStorage.getItem("selecMonth"));
    //console.log(this.monthYear.month_year[0], 'hh');
    localStorage.setItem('monthYear', this.monthYearCal.month_year[0]);
    this.changeMonthYear.emit({ monthYear: this.monthYearCal.month_year[0] })
    this.monthYearPopup = !this.monthYearPopup;
    this.getValuationData();
    const id = localStorage.getItem('store_id');
    const monthyear = localStorage.getItem('monthYear');
    this.getdashsection_grades(id, monthyear);
  }

  closeMonnthYearPopup() {
    // localStorage.setItem("selecMonth", JSON.stringify(this.comm.MonthsDdl[0]));
    // this.monthYear = JSON.parse(localStorage.getItem("selecMonth"));
    this.monthYearPopup = !this.monthYearPopup;

    //this.retunIndexValue();
    //this.i = this.valueChangeIndex != undefined ? this.valueChangeIndex : 0;


    //var ele = document.getElementsByName("mth");
    //this.0resetRadioButton();

    //}



  }

  getlen(str) {
    let len = 0
    if (str != null) {
      len = str.length


    }
    //this.resetButtons(len - 1)
    // console.log(len, 'vv');

    return len - 1;

  }
  //Vishnu's code for pagenation
  monthDDL: any = [];
  lengthLast = 12;
  pagenatation() {

    this.monthDDL = [];

    for (let i = 0; i < this.comm.MonthsDdl.length; i++) {
      //console.log(this.comm.MonthsDdl[i]);
      //
      if (this.comm.MonthsDdl[i] != undefined) {

        this.monthDDL.push({
          month_year: this.comm.MonthsDdl[i].month_year, year: this.comm.MonthsDdl[i].year, month_number: this.comm.MonthsDdl[i].month_number,
          month: this.comm.MonthsDdl[i].month, short_month: this.comm.MonthsDdl[i].short_month
        });

      }
    }


    this.lengthLast = (12 * this.first)
    //console.log(this.lengthLast, 'kk');
    return this.monthDDL;
    //console.log(this.comm.MonthsDdl);

  }


  i = -1;

  next() {
    //     //this.checkedRadiobuttons();
    //     //this.pagenatation()
    //     this.i++;
    //     this.getChecked(this.comm.MonthsDdl[this.i])
    //     this.monthYearCal = this.comm.MonthsDdl[this.i];
    // 
    console.log(this.i);
    //     if (this.i > this.comm.MonthsDdl.length) {
    //       alert('hi');
    //     }
    //     if (this.i >= this.lengthLast) {
    //       this.monthYearCal = this.monthYearCalCopy;
    // 
    // 
    //       if (this.first < this.numPages()) {
    //         this.first++;
    //         //changePage(current_page);
    //         this.pagenatation();
    // 
    //         console.log(this.comm.MonthsDdl.length, this.i);
    // 
    //       }
    // 
    //     }

    this.maxYear;
    //this.minYear++;
    this.yearToShow++;
    // this.curyear++;
    this.filterByYear(this.yearToShow);;
    this.monthsToShow = "";
    this.j = 0;
  }
  prev() {
    // //this.checkedRadiobuttons();
    // this.i--;
    // console.log(this.i, 'kk');
    // this.getChecked(this.comm.MonthsDdl[this.i])
    // this.monthYearCal = this.comm.MonthsDdl[this.i];
    // if (this.i <= this.lengthLast) {
    //   this.monthYearCal = this.monthYearCalCopy;
    //   //this.monthDDL = [];
    //   if (this.first > 1) {
    //     this.first--;
    //     //changePage(current_page);
    //     this.pagenatation();
    //   }
    // }
    this.maxYear;
    this.minYear;
    this.yearToShow--;
    this.filterByYear(this.yearToShow);;
    this.monthsToShow = "";
    this.j = 0;
    console.log(this.maxYear, this.minYear, 'll');
  }

  prevdms() {
    this.curyear--;
  }

  nextdms() {
    this.curyear++;
  }
  numPages() {
    return Math.ceil(this.comm.MonthsDdl.length / this.last);
  }






  retunIndexValue() {
    for (let m = 0; m < this.comm.MonthsDdl.length; m++) {
      if (this.comm.MonthsDdl[m].year == this.monthYear.year[0]) {
        if (this.comm.MonthsDdl[m].short_month == this.monthYear.short_month[0]) {
          return m;

        }
      }
    }
  }


  parseConvert(item) {

    let variable = JSON.parse(localStorage.getItem("selecMonth"));
    if (variable != null && variable != undefined) {
      if (item == 'month') {
        return variable.short_month[0]
      }
      else {
        return variable.year[0]
      }
    }
  }

  gridstoreviewclick() {
    setTimeout(() => {
      this.comm.selectedlink = 8;
      this.comm.selectedStore = [];
      this.changeGrid.emit(this.router.navigate(['4']))
    }, 1000);
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  changeDate() {
    let myDate = this.selectedYearGrp + '' + this.selectedmonthYearGrp;
    this.yearToShowGrp = this.selectedYearGrp;
    this.monthsToShowGrp = this.getmonth(this.selectedmonthYearGrp - 1);
    this.changeMonthYear.emit({ monthYear: myDate });
    this.monthYearPopupGrp = !this.monthYearPopupGrp;
    this.getValuationData();
  }

  con: any = [];
  filterByYear(Year) {
    this.con = [];
    for (let i = 0; i < this.monthDDL.length; i++) {
      if (this.monthDDL[i].year[0] === Year.toString()) {
        this.con.push(this.monthDDL[i].short_month[0])
      }
    }
    console.log(this.con, Year, 'kk');
    setTimeout(() => {
      //this.checkToShow()
    }, 1500);

  }
  j = 0;
  checkToShow(month) {
    var allMonths = ['Jan', 'Feb', 'Mar', "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    this.con.sort(function (a, b) {
      return allMonths.indexOf(a) - allMonths.indexOf(b);
    })
    //console.log(this.con);
    //console.log(this.j);
    if (this.j == this.con.length) {
      this.j = 0;
    }
    if (this.j < this.con.length) {
      if (this.con[this.j] === month) {
        this.j++;
        return true;

      } else {

      }

    }




  }
  maxYear = 0;
  minYear = 0;
  curmaxYear = 0;
  curminYear = 0;
  findMaxMinYear() {

    let years: any[] = [];
    for (let i = 0; i < this.monthDDL.length; i++) {
      years.push(parseInt(this.monthDDL[i].year[0]));
    }
    let year = (localStorage.getItem("CurrentYear"));
    this.curmaxYear = Number(year);
    this.curminYear = Math.min(...years)

    this.maxYear = Math.max(...years)
    this.minYear = Math.min(...years)
    //console.log(maxYear, minYear);

  }

  openMonthYearPopupGrp() {
    this.monthYearGrp = this.yearToShowGrp;
    this.monthYearPopupGrp = !this.monthYearPopupGrp;
  }

  getmonth(monthsToShow) {
    return (monthsToShow == '0' ? 'January' : monthsToShow == '1' ? 'February' : monthsToShow == '2' ? 'March' : monthsToShow == '3' ? 'April' : monthsToShow == '4' ? 'May' : monthsToShow == '5' ? 'June' : monthsToShow == '6' ? 'July' : monthsToShow == '7' ? 'August' : monthsToShow == '8' ? 'September' : monthsToShow == '9' ? 'October' : monthsToShow == '10' ? 'November' : monthsToShow == '11' ? 'December' : this.monthsToShow);
  }

  getcheckedgrp(item) {
    if (this.yearToShowGrp == this.monthYearGrp) {
      if (this.monthsToShowGrp.indexOf(item) !== -1) {
        return true;
      }
    }
  }

  prevGrp() {
    this.maxYear;
    this.minYear;
    this.monthYearGrp--;
  }

  nextGrp() {
    this.maxYear;
    this.minYear;
    this.monthYearGrp++;
  }

  handleChangeGrp(item, i) {
    // this.monthsToShowGrp
    this.selectedYearGrp = this.monthYearGrp;
    if (i < 10)
      this.selectedmonthYearGrp = '0' + (i + 1);
    else
      this.selectedmonthYearGrp = i + 1;
  }


  closeMonnthYearPopupGrp() {
    this.monthYearPopupGrp = !this.monthYearPopupGrp;
  }

  bookmarksaction() {
    const obj = {
      "id": 1,
      "userid": this.userInfo.id,
      "bookmark": this.comm.bookmarkid,
      "DealerId": this.comm.localStorageItem('store_id'),
      "status": this.typeaction == 'A' ? 'Y' : 'N'
    };
    this.api.savebookmark(obj).subscribe(data => {
      if (data['status'] == 200) {
        this.alertify.success(this.typeaction == 'A' ? "Added to Favorites" : "Removed from Favorites")
        if (this.comm.bookmarkid == '4') {
          this.bindbookmarkdata.emit();
        }
        this.Getbookmarkdata();
      }
      else {
        this.alertify.warning('Not Added to Favorites');
      }
    });
  }

  alertContent: any = "";
  typeAlert: any = "";
  typeaction: any = "";
  editbookmark(temp, type) {
    this.alertContent = (type == 'A') ? 'Do you want to add this page to your Favorites?' : 'Do you want to remove this page from your Favorites?';
    this.typeAlert = 'S';
    this.typeaction = type;
    this.ngbActiveModal = this.ngbmodal.open(temp, { size: 'sm', backdrop: 'static', windowClass: 'Center-aligned' });
  }

  onCloseAlert(type, temp) {
    if (type == "Y") {
      this.bookmarksaction();
      this.ngbActiveModal.close();
    } else if (type == "N") {
      this.ngbActiveModal.close();
    }
  }

  isbookmark: boolean = false;
  checkbookmark() {
    if (this.userInfo.bookmark_ids != "") {
      var split_str = this.userInfo.bookmark_ids.split(",");
      if (split_str.indexOf(this.comm.bookmarkid.toString()) !== -1) {
        this.isbookmark = true;
      } else {
        this.isbookmark = false;
      }
    } else {
      this.isbookmark = false;
    }
    return this.isbookmark;
  }

  bookmarkslist: any = [];
  Getbookmarkdata() {
    let body = {
      userid: this.userInfo.id,
      DealerId: localStorage.getItem('store_id')
    };
    this.api.Getbookmarkslist(body).subscribe((res: any) => {
      this.bookmarkslist = [];
      if (res.status == 200) {
        var bookmarks = Array.prototype.map.call(res.response, s => s.ub_bl_pageid).toString(); // "A,B,C"
        this.favorites = res.response.filter((f => f.bookmark.toLowerCase() != 'dashboard'));
        let obj = {
          id: this.userInfo.id, firstname: this.userInfo.firstname, lastname: this.userInfo.lastname,
          email: this.userInfo.email, phone1: this.userInfo.phone1, city: this.userInfo.city,
          state: this.userInfo.state, zip: this.userInfo.zip, profilepic: this.userInfo.profilepic, title: this.userInfo.title,
          roleid: this.userInfo.roleid, userid: this.userInfo.userid, bookmark_ids: bookmarks, moduleids: this.userInfo.moduleids, stores: this.userInfo.stores,
          sc_defaultscreen: this.userInfo.sc_defaultscreen, sc_defaultstore: this.userInfo.sc_defaultstore
        }
        localStorage.setItem("npUserInfo", JSON.stringify(obj));
        this.userInfo = JSON.parse(localStorage.getItem("npUserInfo"));
        this.checkbookmark();
      }
    }, err => {
      // this.isLoading = false;
    });
  }

  selectstoreforinit(id: any) {
    //getted from event
    console.log(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));

    for (let i = 0; i < this.comm.storeinfo.length; i++) {
      if (this.comm.storeinfo[i].Store_Id == id) {
        let MonthsDdl = this.parseXml(this.comm.storeinfo[i].monthsdata);
        this.comm.store_title = this.comm.storeinfo[i].Store_Name;
        this.comm.MonthsDdl = MonthsDdl.Months.month;
        console.log(this.comm.MonthsDdl, 'llo');
        this.comm.selectedStore = this.comm.storeinfo[i];
        localStorage.setItem('monthYear', this.comm.MonthsDdl[0].month_year[0]);
        this.api.setActionPlanActualValueObs(this.comm.MonthsDdl[0]);
        console.log(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));

        let Weightavg = (this.comm.storeinfo[i].Weightavg != '') ? this.parseXml(this.comm.storeinfo[i].Weightavg) : [];
        let wavg = (this.comm.storeinfo[i].Weightavg != '') ? JSON.stringify(Weightavg.weightedavg.avgdata) : "";
        localStorage.setItem('WeightAvg', wavg);
        console.log('weightavg' + wavg);


      }
    }
    this.first = 1;
    this.last = 12;
    localStorage.setItem("selecMonth", JSON.stringify(this.comm.MonthsDdl[0]));
    this.api.setActionPlanActualValueObs(this.comm.MonthsDdl[0]);
    this.monthYear = JSON.parse(localStorage.getItem("selecMonth"));

    this.monthYearCal = this.monthYear;
    this.monthDDL = [];
    this.i = 0;
    this.monthDDL = this.comm.MonthsDdl.length > 12 ? this.pagenatation() : this.comm.MonthsDdl;
    //this.checkedRadiobuttons();
    localStorage.setItem('store_id', id);
    //  this.viewflag = 0;

    console.log(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));
    this.getdashsection_grades(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));
    this.getvaluationvalue(this.comm.localStorageItem('store_id'), this.comm.localStorageItem('monthYear'));
    this.comm.selectedlink = 3;
    // this.changeStoreInfo.emit({ storeId: id })

  }

  onFavChng(urldata) {
    // // console.log(id);
    // // var urldata = this.favorites[(ev.target.options.selectedIndex)-1];
    // if (urldata.subpageurl == '0') {
    //   this.router.navigate([urldata.pageurl]);
    // }
    // else {
    //   this.comm.selectedlink = urldata.subpageurl;
    //   this.router.navigate(['1']);
    // }

    const linkMap = {
      1: 1,  
      10:1,  
      6: 8,
      5: 2,
      3: 7,
      9: 8,
      7: 5,
      15: 9
  };
  const selectedLink = linkMap[urldata.ub_bl_pageid];
  if (selectedLink !== undefined) {
      this.comm.selectedlink = selectedLink;
  } else {
      console.warn('Unhandled ub_bl_pageid:', urldata.ub_bl_pageid);
      return;
  }if (selectedLink === 1) {
    this.router.navigate([urldata.pageurl]);
} else if (urldata.subpageurl === '0') {
      this.router.navigate([urldata.pageurl]);
  } else if (urldata.subpageurl) {
      this.router.navigate([urldata.subpageurl]);
  } else {
    this.router.navigate(['1']);
}
  }

  favorites: any = [];
  Getfavdata() {
    let body = {
      userid: this.userInfo.id,
      DealerId: this.comm.storeId != undefined ? this.comm.storeId : localStorage.getItem("store_id")
    };
    // this.isLoading = true;
    this.api.Getbookmarkslist(body).subscribe((res: any) => {
      this.bookmarkslist = [];
      // this.isLoading = false;
      if (res.status == 200) {
        this.favorites = res.response;
        this.favorites = this.favorites.filter((f => f.bookmark.toLowerCase() != 'dashboard'))
        // console.log(this.favorites,'bv');
      }
      else{
        this.Getbookmarkdata();
      }
    }, err => {
      // this.isLoading = false;
    });
  }

  downarrow1: boolean = false;
  downarrow() {
    this.downarrow1 = !this.downarrow1;

  }

  // yearToShowCur:any='';
  getCheckedcurrent(item) {
    if (this.yearToShowSelCur == this.curyear) {
      if (item == this.mnthvalCur) {
        return true;
      }
    }
  }

  handleChangecurrent(item, i) {
    //console.log(i, item);

    // this.monthyear = item;
    //     this.monthYearCal = item;
    //     this.i = i;
    // this.monthsToShow = item;
    // for (let i = 0; i < this.monthDDL.length; i++) {
    //   if (this.yearToShow == this.monthDDL[i].year[0]) {
    //     if (item == this.monthDDL[i].short_month[0]) {
    //       this.monthYearCal = this.monthDDL[i];
    //     }
    //   }
    // }
    this.yearToShowSelCur = this.curyear;
    this.mnthvalCur = item;
  }

  parseConvertCur(item) {
    let mnthval = (localStorage.getItem("CurrentMnth"));
    let year = (localStorage.getItem("CurrentYear"));
    if (mnthval != null && mnthval != undefined && year != null && year != undefined) {
      if (item == 'month') {
        // return variable.month[0]
        // return (mnthval == 'Jan'?'January':mnthval == 'Feb'?'February':mnthval == 'Mar'?'March':mnthval == 'Apr'?'April':mnthval == 'May'?'May':mnthval == 'Jun'?'June':mnthval == 'Jul'?'July':mnthval == 'Aug'?'August':mnthval == 'Sep'?'September':mnthval == 'Oct'?'October':mnthval == 'Nov'?'November':mnthval == 'Dec'?'December':mnthval);
        return mnthval;
      }
      else {
        return year;
      }
    }
  }

  checkToShowcurrent(ind) {
    if (this.curyear == this.curmaxYear) {
      var allMonths = ['January', 'February', 'March', "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let mnthval = this.getmonth(this.maxDate.getMonth());
      allMonths.indexOf(mnthval)
      let res;
      return res = allMonths.indexOf(mnthval) >= ind ? true : false;
    }
    else {
      return true
    }
  }


  parseConvertCurYear(item) {
    let year = (localStorage.getItem("CurrentYear"));
    if (year != null && year != undefined) {
      return parseInt(year);
    }
  }

  getformat(number) {
    let sign = '';
    if (number < 0) {
      number = number.slice(1);
      sign = '-';
    }
    if (number == 0) {
      return 0;
    }
    else {
      // hundreds
      if (number <= 999) {
        return number;
      }
      // thousands
      else if (number >= 1000 && number <= 999999) {
        return this.toFixedSpecial_total(number);
      }
      // millions
      else if (number >= 1000000 && number <= 999999999) {
        return (number / 1000000).toFixed(1).replace(/\.0+$/, '') + 'm';
      }
      // billions
      else if (number >= 1000000000 && number <= 999999999999) {
        return (number / 1000000000).toFixed(1) + 'b';
      }
      else
        return number;
    }
  }

  toFixedSpecial_total(number) {
    if (number != null) {
      var n = number < 0 ? number.slice(1) : number;

      var parts = n.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts[0];
    }
  }

  getnotificationsList() {
    let body = {
      userid: this.userInfo.id,
      type: '',
      Storeid: localStorage.getItem('store_id')
    };
    // this.isLoading = true;
    this.api.Getnotifications(body).subscribe((res: any) => {
      this.comm.notifyslist = [];
      // this.isLoading = false;
      if (res.status == 200) {
        this.comm.notifyslist = res.response;
      }
    }, err => {
      // this.isLoading = false;
    });
  }

  printScreen() {
    this.comm.detailsStyle = true;
    setTimeout(() => {
      window.print();
    }, 1000);
    setTimeout(() => {
      this.comm.detailsStyle = false;
    }, 1000);
  }

  submitEmail() {
    if (this.sentEmail.valid) {
      // this.isLoading = true;  

      this.ngbmodalActive.close();
      this.comm.nonsvg = false;
      this.alertify.warning("Mail is Sending...");
      let printElement = document.getElementById('content');
      let pagename = (this.comm.pageid == '4' ? 'Summary' : this.comm.selectedlink == '10' ? 'Performance' : this.comm.pageid == '31' ? 'Dashboard' : this.comm.pageid == '51' ? 'HotNot' : this.comm.selectedlink == '4' ? 'Details' : 'Default')
      html2canvas(printElement, {
        logging: true, allowTaint: false,
        useCORS: true
      }).then(canvas => {
        let imgWidth = 210;
        let pageHeight1 = 250;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL('image/png')
        let pdfData = new jsPDF('p', 'mm', 'a4', true); // A4 size page of PDF
        let position = 0;
        const pageWidth = pdfData.internal.pageSize.getWidth();
        const pageHeight = pdfData.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        const marginX = (pageWidth - canvasWidth) / 2;
        const marginY = (pageHeight - canvasHeight) / 2;
        pdfData.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, 'FAST');
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdfData.addPage();
          pdfData.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, 'FAST');
          heightLeft -= pageHeight;
        }
        // pdfData.save('report.pdf'); // Generated PDF
        var myBlob = new Blob();
        myBlob = pdfData.output('blob');
        var myFiles: File;
        myFiles = this.blobToFile(myBlob, pagename + '.pdf');
        var fd = new FormData();     // To carry on your data  
        fd.append('to_email', this.sentEmail.value.sentto)
        fd.append('cc_email', this.sentEmail.value.senttocc)
        fd.append('bcc_email', this.sentEmail.value.senttobcc)
        fd.append('subject', this.sentEmail.value.subject)
        fd.append('text', this.sentEmail.value.text)
        fd.append('username', this.userInfo.firstname + ' ' + this.userInfo.lastname)
        fd.append('file', myFiles);
        console.log(myFiles);
        this.api.uploadFile(fd).subscribe((data: any) => {
          // this.comm.nonsvg = true;
          // this.isLoading = false;

          if (data.status === 200) {
            // this.isLoading = false;
            this.comm.nonsvg = false;
            this.alertify.success(data.response);
          } else {
            this.alertify.error("Mail sending failed, please try after sometime");
          }
        },
          (error) => {
            this.isLoading = false;
            this.comm.nonsvg = false;
            console.log(error);
          })
      }, (error) => {
        this.comm.nonsvg = false;
        this.isLoading = false;
        console.log(error);
      });
    }
  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
  }

  openModalForemailsent(content) {
    this.sentEmail.reset();
    let pagename = (this.comm.pageid == '4' ? 'Summary' : this.comm.selectedlink == '10' ? 'Performance' : this.comm.pageid == '31' ? 'Dashboard' : this.comm.pageid == '51' ? 'HotNot' : this.comm.selectedlink == '4' ? 'Details' : 'Default')
    this.sentEmail.controls['subject'].setValue(pagename + ' - Report');
    this.sentEmail.controls['text'].setValue('Please find the attached document');
    this.comm.nonsvg = true;
    this.ngbmodalActive = this.ngbmodal.open(content, { size: 'lg', backdrop: 'static' });
  }

  closeMailPopup() {
    this.comm.nonsvg = false;
    this.ngbmodalActive.close();
  }

  valuation: 0;
  getValuationData() {
    let obj = {
      storeid: this.comm.storeId != undefined ? this.comm.storeId : localStorage.getItem("store_id"),
      monthyear: this.comm.localStorageItem('monthYear'),
    }
    this.api.getValuation(obj).subscribe((res: any) => {
      // console.log(res);
      this.valuation = res.response[0].Valuation_calc;
    })
  }


}