import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class Common {
    headerTitle:string;
    storeinfo: any = []
    cash: any = [];
    gross: any = [];
    expence: any = [];
    comp: any = [];
    profit: any = [];
    revenue: any = [];
    staffing: any = [];
    pageid: any = 0;
    head: boolean = false;
    selectedItem: any = 1;
    gra_cash: any = 'D';
    gra_revenue: any = 'D';
    gra_expense: any = 'D';
    gra_comp: any = 'D';
    gra_profit: any = 'D';
    MonthsDdl: any;
    store_title = "";
    fromHEAD: Boolean = true;
    selectedlink: any = 3;
    dbflag:any=0;
    selectedStore:any = {};
    //actionPlanActiveFlag = false;
    grididividual:any=0
    dbkey:any="";
    Msgconv_id:any=126;
    title: any;
    bookmarkid:any=0;
    proimg:any;
    val_flag:any=0;
    moduleids:any = "";
    modstoreids:any = "";
    hash:any="new";
    curentMonth="January";
    storeId:any;
    notifyslist : any = [];
    detailsStyle:boolean=false;
    nonsvg:boolean=false;
    valuation:any=0;
    // axelonecust:boolean=false;
    public localStorageItem(id: string): string {
        return localStorage.getItem(id);
    }
}